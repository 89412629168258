<template>
  <div class="express-info-container" v-show="expList.length">
    <p class="express-name">
      物流公司：{{ expressInfo.expName }}
      <van-image :src="expressInfo.logo"/>
    </p>
    <p class="express-name">
      快递单号：{{ expressInfo.number }}
    </p>
    <van-steps direction="vertical" :active="0">
      <van-step
          v-for="{ status, time } in expList"
          :key="time"
      >
        <h3>{{ status }}</h3>
        <p>{{ time }}</p>
      </van-step>
    </van-steps>
  </div>
</template>

<script>
import { queryExpress } from '@/api/normalOrder';
import { queryExpressByRecordId } from '@/api/warehouse';
import { queryExpressByIntegralOrderId } from '@/api/integralGoods';

export default {
  name: 'ExpressInfo',
  props: {
    orderId: {
      default: () => '',
    },
    recordId: {
      default: () => '',
    },
    noErrorTip: {
      default: () => false,
    },
    serviceModel: {
      default: () => 'order', // order：根据订单查询（默认）；express：根据快递单号查询；
    },
  },
  data() {
    return {
      expressInfo: {},
    };
  },
  computed: {
    expList() {
      return this.expressInfo.list || [];
    },
  },
  created() {
    this.$watch(() => [this.orderId, this.recordId], () => {
      let { orderId, recordId, serviceModel } = this;
      let service = queryExpress;
      let id = orderId;
      if (serviceModel === 'express') {
        service = queryExpressByRecordId;
        id = recordId;
      }
      if (serviceModel === 'integral') {
        service = queryExpressByIntegralOrderId;
        id = orderId;
      }

      this.$emit('update:loading', true);
      service({
        id,
      }, {
        headers: { noErrorTip: this.noErrorTip },
      }).then(res => {
        this.expressInfo = res || {};
        this.$emit('update:loading', false);
        this.$emit('change', res);
      });
    }, { immediate: true });
  },
};
</script>

<style lang="less" scoped>
.express-info-container {
  .express-name {
    display: flex;
    align-items: center;
    padding: 5px 0;
    line-height: 1.2;
    font-size: 14px;
    color: #666;
    background-color: #fff;
  }

  .van-image {
    margin-left: 10px;
    width: 30px;
    height: 30px;
  }

  .van-step__title {
    h3 {
      font-size: 14px;
      font-weight: normal;
      color: #666;
    }

    p {
      margin-top: 4px;
      font-size: 14px;
      color: #999;
    }
  }

  .van-step--process {
    .van-step__title {
      h3 {
        font-weight: bolder;
        color: @primary-color;
      }

      p {
        color: @primary-color;
      }
    }
  }
}

</style>
