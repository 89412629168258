<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container class="order-detail-container nav-page-container">
      <div class="order-status-container">
        <div
            v-if="orderStatusIconName"
            class="status-icon-container"
        >
          <van-icon :name="orderStatusIconName"/>
        </div>
        <div class="status-text">
          {{ orderStatusText }}
        </div>
        <div v-if="orderStatusTip" class="status-tip">
          {{ orderStatusTip }}
        </div>
      </div>

      <goods-info
          class="order-item-card"
          view-model="small"
          :value="{
          goodsImgSrc: getFirstImg(orderInfo),
          goodsName: orderInfo.name,
          goodsDesc: orderInfo.subName,
        }"
      >
        <template #price>
          <div v-html="priceAndIntegralText"></div>
        </template>

        <template #price-right>
          ×{{ orderInfo.num }}
        </template>
      </goods-info>

      <van-cell-group>
        <van-cell title="商品总额：" :value="totalPriceText"/>
        <van-cell title="运费：" :value="`+￥${orderInfo.carriage || 0}`"/>
        <div class="last-price-container">
          实付金额：
          <span v-html="lastPrice"></span>
        </div>
      </van-cell-group>

      <van-cell-group>
        <van-cell title="订单编号：" :value="orderInfo.orderNo"/>
        <van-cell title="下单时间：" :value="orderInfo.orderTime"/>
        <van-cell title="支付方式：" :value="payWayText"/>
        <van-cell title="支付时间：" :value="orderInfo.payTime"/>
      </van-cell-group>

      <van-cell-group>
        <van-cell title="配送方式：" :value="deliveryWayText"/>
        <van-cell title="收货人：" :value="orderInfo.revicerName"/>
        <van-cell title="收货人电话：" :value="orderInfo.revicerMobile"/>
        <van-cell title="收货地址：" :value="receiverAddress"/>
        <van-cell title="留言：" :value="orderInfo.memo"/>
      </van-cell-group>

      <div
          v-if="isShowExpress && hasExpressInfo"
          class="order-express-info-container"
          :class="{ openExpressInfo }"
      >
        <express-info
            :order-id="orderInfo.id"
            :no-error-tip="true"
            service-model="integral"
            @change="data => {
            this.hasExpressInfo = !!data;
          }"
        />
        <div class="shrink" @click="openExpressInfo = !openExpressInfo">
          {{ openExpressInfo ? '收起' : '展开' }}
        </div>
      </div>

      <div class="order-detail-bottom-bar">
        <!--      <van-button-->
        <!--          size="small" round plain color="#666"-->
        <!--          @click="() => $router.go(-2)"-->
        <!--      >返回首页-->
        <!--      </van-button>-->

        <van-button
            v-if="getBtnShow('取消订单', orderInfo.status)"
            size="small" round plain color="#666"
            @click="handleOnCancelOrder"
        >取消订单
        </van-button>

        <van-button
            v-if="getBtnShow('退换/售后', orderInfo.status)"
            size="small" round plain color="#666"
            @click="handleApplyForReturn"
        >退换/售后
        </van-button>

        <van-button
            v-if="getBtnShow('付款', orderInfo.status)"
            size="small" round type="primary"
            @click="handlePayment"
        >付款
        </van-button>

        <van-button
            v-if="getBtnShow('再次购买', orderInfo.status)"
            size="small" round plain type="primary"
            @click="handleBuyAgain"
        >再次购买
        </van-button>

        <van-button
            v-if="getBtnShow('确认收货', orderInfo.status)"
            size="small" round type="primary"
            @click="handleConfirmOrder"
        >确认收货
        </van-button>
      </div>
    </page-container>
  </div>
</template>

<script>
import {
  DENG_DAI_PIN_TUAN_WAN_CHENG,
  DAI_FU_KUAN,
  DAI_FA_HUO,
  DAI_SHOU_HUO,
  YI_WAN_CHENG,
  YI_GUAN_BI,
  WEI_QUAN_SHEN_QING,
  WEI_QUAN_WAN_CHENG,
  DING_DAN_QU_XIAO,
  ORDER_STATUS_MAP, APPLY_FOR_RETURN_STATUS,
} from '@/const/orderStatus';

import GoodsInfo from '@/components/GoodsInfo';
import { objectGetter } from '@/util';
import { getUserInfo } from '@/api/user';
import { Dialog, Toast } from 'vant';
import ExpressInfo from '@/components/ExpressInfo';
import { wxJsPay } from '@/util/wxJsPay';
import { getIntegralOrderInfoById, integralPayment, integralRecordReceiving } from '@/api/integralGoods';
// import { getIntegralOrderObj as getIntegralOrderInfoById } from '@/api/test';

export default {
  name: 'integralOrderDetail',
  components: { ExpressInfo, GoodsInfo },
  data() {
    return {
      userInfo: {},
      orderInfo: {},
      openExpressInfo: false,
      hasExpressInfo: true,
    };
  },
  computed: {
    // 商品单价
    priceAndIntegralText() {
      let { shopAmount: goodsPrice, shopIntegralUsed: integralUsed } = this.orderInfo || {};
      let priceHtml = goodsPrice ? [`<span class="integral-price">${goodsPrice}</span>`] : [];
      let integralUsedHtml = integralUsed ? [`<span class="integral">${integralUsed}</span>`] : [];
      return [...priceHtml, ...integralUsedHtml].join(' + ');
    },
    // 商品总额
    totalPriceText() {
      let { amount, integralUsed } = this.orderInfo || {};
      let priceHtml = amount ? ['￥' + amount] : [];
      let integralUsedHtml = integralUsed ? [integralUsed + '积分'] : [];
      return [...priceHtml, ...integralUsedHtml].join(' + ');
    },
    lastPrice() {
      let { amount, integralUsed } = this.orderInfo || {};
      let priceHtml = amount ? [`<span class="integral-price">${amount}</span>`] : [];
      let integralUsedHtml = integralUsed ? [`<span class="integral">${integralUsed}</span>`] : [];
      return [...priceHtml, ...integralUsedHtml].join(' + ');
    },
    // 订单状态icon名
    orderStatusIconName() {
      let { orderInfo: { status = '' } } = this;
      return {
        '00': 'friends-o', //<van-icon name="friends-o" />
        '01': 'gold-coin-o',
        '02': 'clock-o',
        '03': 'logistics',
        '04': 'certificate',
        '05': 'close',
        '06': 'revoke',
        '07': 'certificate',
        '08': 'clear',
        '09': 'wap-home',
      }[status] || '';
    },
    orderStatusText() {
      let { orderInfo: { status = '' } } = this;
      return ORDER_STATUS_MAP[status] || '';
    },
    // 订单状态提示信息
    orderStatusTip() {
      let { orderInfo: { status = '' } } = this;
      return {
        '00': '请耐心等待拼购完成',
        '01': '系统将自动取消订单，请尽快付款',
        '02': '即将为您发送订单',
        '03': '收到货后将自动确认订单',
        '04': '感谢您的购买,欢迎再次光临',
        '05': '订单已关闭,请前往商城再次购买',
        '06': '维权申请',
        '07': '维权已完成',
        '08': '',
      }[status] || '';
    },
    // 支付方式
    payWayText() {
      let { orderInfo: { payWay = '' } } = this;
      return {
        '01': '微信支付',
        '02': '支付宝支付',
        '03': '银联支付',
        '04': '余额支付',
        '05': '积分支付',
        '06': '微信加积分支付',
        '07': '支付宝加积分',
        '08': '银联加积分',
        '09': '余额加积分',
      }[payWay] || '';
    },
    // 配送方式
    deliveryWayText() {
      let { orderInfo: { deliveryWay = '' } } = this;
      return {
        '01': '快递',
      }[deliveryWay] || '其它';
    },
    // 收货地址
    receiverAddress() {
      let addressTextArr = [];
      let { orderInfo } = this;
      [
        'revicerProvince',
        'revicerCity',
        'revicerCountry',
        'revicerStreet',
        'revicerAddress',
      ].forEach(key => {
        orderInfo[key] && addressTextArr.push(orderInfo[key]);
      });

      return addressTextArr.join(' ');
    },
    // 是否能展示快递
    isShowExpress() {
      let { orderInfo: { status = '' } } = this;
      return [
        DAI_SHOU_HUO,
        YI_WAN_CHENG,
        YI_GUAN_BI,
        WEI_QUAN_SHEN_QING,
        WEI_QUAN_WAN_CHENG,
      ].includes(status);
    },
  },
  created() {
    getUserInfo().then(res => {
      this.userInfo = res || {};
    });

    getIntegralOrderInfoById({
      integralOrderId: this.$route.query.integralOrderId,
    }).then(res => {
      this.orderInfo = res || {};
    });
  },
  methods: {
    objectGetter,

    getBtnShow(btnName, status) {
      if (!status)
        return  false;

      if (btnName === '取消订单') {
        return false;
        // return status === DAI_FU_KUAN;
      }
      if (btnName === '付款') {
        return status === DAI_FU_KUAN;
      }
      if (btnName === '确认收货') {
        return status === DAI_SHOU_HUO;
      }
      if (btnName === '退换/售后') {
        // return false;
        return APPLY_FOR_RETURN_STATUS.includes(status);
      }
      if (btnName === '取消申诉') {
        // return false;
        return status === WEI_QUAN_SHEN_QING;
      }
      if (btnName === '再次购买') {
        return status !== DENG_DAI_PIN_TUAN_WAN_CHENG && status !== DAI_FU_KUAN;
      }
      return false;
    },
    getFirstImg(order) {
      return objectGetter(order, 'imageList.0.url');
    },
    formatPrice(price, digits = 10) {
      return parseFloat(price.toFixed(digits));
    },

    // 付款
    handlePayment() {
      let { orderInfo, userInfo } = this;

      // if (userInfo.status === '01') {
      //   Dialog.alert({
      //     message: `请先完善个人信息，再购买商品`,
      //     confirmButtonText: '去完善',
      //   }).then(() => {
      //     this.$router.replace('/userInfo');
      //   });
      //   return;
      // }

      Dialog.confirm({
        title: `是否确认支付`,
        // message: '弹窗内容',
        confirmButtonText: '确认',
        beforeClose: (action, done) => {
          // 点击确认支付
          if (action === 'confirm') {

            // 微信支付
            if (orderInfo.payWay === '01' || orderInfo.payWay === '06') {
              // TODO: 微信支付
              // Toast('微信支付开发中');
              wxJsPay(this.orderInfo, 'integralPaymentAgain').then(res => {
                if (res) {
                  Toast('微信支付成功');
                  this.backAndRefreshOrderList();
                }
              });
              done();
            }

            // 积分兑换
            else {
              let toast = Toast.loading({
                message: '积分支付中...',
                forbidClick: true,
              });
              done();
              integralPayment({ id: orderInfo.id }).then(res => {
                // toast.clear();
                let isPaySuccess = objectGetter(res, 'result') === 'true';
                if (isPaySuccess) {
                  Toast('积分支付成功');
                  this.backAndRefreshOrderList();
                }
              });
            }
          } else {
            // 点击取消
            done();
          }
        },
      }).catch(() => {
      });
    },
    // 取消订单
    handleOnCancelOrder() {
      let { linkGoods = {} } = this.orderInfo;
      Dialog.confirm({
        title: `确认取消订单商品  【${linkGoods.goodsName}】么？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            cancelOrder({ orderId: this.orderInfo.id }).then(res => {
              if (res === true) {
                this.backAndRefreshOrderList();
                done();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },
    // 维权/售后
    handleApplyForReturn() {
      this.$router.push({
        path: '/integralApplyForReturn',
        query: {
          integralOrderId: this.orderInfo.id,
        }
      });
    },
    // 再次购买
    handleBuyAgain() {
      this.$store.commit('integralPayment/setRefreshing', true);
      let integralGoodsId = this.orderInfo.commodityId;
      this.$router.push({
        path: '/integralPayment',
        query: { integralGoodsId },
      });
    },
    // 确认收货
    handleConfirmOrder() {
      let { orderInfo } = this;
      Dialog.confirm({
        title: `是否确认收货？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            integralRecordReceiving({ id: orderInfo.id }).then(res => {
              done();
              if (res === true) {
                this.onRefresh();
              } else {
                Toast('确认收货失败');
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },

    backAndRefreshOrderList() {
      this.$store.commit('integralOrderList/setRefreshing', true);
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
@icon-container-width: 50px;

.order-detail-container {
  padding: 0 0 calc(64px + constant(safe-area-inset-bottom)) 0;
  padding: 0 0 calc(64px + env(safe-area-inset-bottom)) 0;

  .order-status-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    padding: 0 15px 0 @icon-container-width;
    height: 80px;
    color: #fff;
    background: linear-gradient(to right, #ff9c57, #ff7c1b);

    .status-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: @icon-container-width;
      height: 100%;

      .van-icon {
        font-size: 24px;
      }
    }

    .status-text {
      line-height: 1;
      font-size: 18px;
      font-weight: bolder;
    }

    .status-tip {
      margin-top: 7px;
      line-height: 1;
      font-size: 12px;
    }
  }

  .order-item-card {
    margin: 10px;
    padding: 10px;
    border-radius: 15px;
    background-color: #fff;
  }

  .van-cell-group + .van-cell-group {
    margin-top: 10px;
  }

  .order-express-info-container {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    margin: 10px 0;
    padding: 10px 10px 30px 10px;
    max-height: 188px;
    overflow: hidden;
    background-color: #fff;

    .shrink {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      background-color: #fff;
      color: #666;
    }

    &.openExpressInfo {
      max-height: none;
    }
  }

  .order-detail-bottom-bar {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0 contant(safe-area-inset-bottom) 0;
    padding: 0 0 env(safe-area-inset-bottom) 0;
    width: 100%;
    height: 54px;
    background-color: #fff;

    button {
      padding: 0 12px;
      font-size: 14px;
      margin: 0 10px;
    }
  }

  /deep/ .goods-image {
    height: 0;
  }

  /deep/ .goods-info-item .goods-info-detail {
    height: auto;

    .goods-name {
      margin-bottom: .5em;
      -webkit-line-clamp: 2;
    }

    .goods-price {
      line-height: 20px;
    }
  }

  /deep/ .integral-price {
    font-size: 18px;
    font-weight: bolder;
    color: #e93b3d;

    .num {
      line-height: 20px;
    }

    &:before {
      content: '¥ ';
      font-size: 12px;
      font-weight: normal;
    }
  }

  /deep/ .integral {
    font-size: 18px;
    font-weight: bolder;
    color: #ffa800;

    &:after {
      content: '积分';
      margin-left: 3px;
      font-size: 12px;
      font-weight: normal;
    }
  }

  /deep/ .num {
    text-align: right;
  }

  .last-price-container {
    padding: 25px 15px;
    text-align: right;

    /deep/ .integral-price {
      font-size: 22px;

      &:before {
        font-size: 14px;
      }
    }

    /deep/ .integral {
      font-size: 22px;

      &:after {
        font-size: 14px;
      }
    }

    .last-price {
      //font-size: 24px;
      //font-weight: bolder;
      //color: #e93b3d;
      //white-space: nowrap;
      //overflow-x: auto;
      //
      //&:before {
      //  content: '¥ ';
      //  font-size: 14px;
      //  font-weight: normal;
      //}
    }
  }


}
</style>
