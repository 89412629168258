var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"title":_vm.$route.meta.name,"left-arrow":""},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('page-container',{staticClass:"order-detail-container nav-page-container"},[_c('div',{staticClass:"order-status-container"},[(_vm.orderStatusIconName)?_c('div',{staticClass:"status-icon-container"},[_c('van-icon',{attrs:{"name":_vm.orderStatusIconName}})],1):_vm._e(),_c('div',{staticClass:"status-text"},[_vm._v(" "+_vm._s(_vm.orderStatusText)+" ")]),(_vm.orderStatusTip)?_c('div',{staticClass:"status-tip"},[_vm._v(" "+_vm._s(_vm.orderStatusTip)+" ")]):_vm._e()]),_c('goods-info',{staticClass:"order-item-card",attrs:{"view-model":"small","value":{
        goodsImgSrc: _vm.getFirstImg(_vm.orderInfo),
        goodsName: _vm.orderInfo.name,
        goodsDesc: _vm.orderInfo.subName,
      }},scopedSlots:_vm._u([{key:"price",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.priceAndIntegralText)}})]},proxy:true},{key:"price-right",fn:function(){return [_vm._v(" ×"+_vm._s(_vm.orderInfo.num)+" ")]},proxy:true}])}),_c('van-cell-group',[_c('van-cell',{attrs:{"title":"商品总额：","value":_vm.totalPriceText}}),_c('van-cell',{attrs:{"title":"运费：","value":("+￥" + (_vm.orderInfo.carriage || 0))}}),_c('div',{staticClass:"last-price-container"},[_vm._v(" 实付金额： "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.lastPrice)}})])],1),_c('van-cell-group',[_c('van-cell',{attrs:{"title":"订单编号：","value":_vm.orderInfo.orderNo}}),_c('van-cell',{attrs:{"title":"下单时间：","value":_vm.orderInfo.orderTime}}),_c('van-cell',{attrs:{"title":"支付方式：","value":_vm.payWayText}}),_c('van-cell',{attrs:{"title":"支付时间：","value":_vm.orderInfo.payTime}})],1),_c('van-cell-group',[_c('van-cell',{attrs:{"title":"配送方式：","value":_vm.deliveryWayText}}),_c('van-cell',{attrs:{"title":"收货人：","value":_vm.orderInfo.revicerName}}),_c('van-cell',{attrs:{"title":"收货人电话：","value":_vm.orderInfo.revicerMobile}}),_c('van-cell',{attrs:{"title":"收货地址：","value":_vm.receiverAddress}}),_c('van-cell',{attrs:{"title":"留言：","value":_vm.orderInfo.memo}})],1),(_vm.isShowExpress && _vm.hasExpressInfo)?_c('div',{staticClass:"order-express-info-container",class:{ openExpressInfo: _vm.openExpressInfo }},[_c('express-info',{attrs:{"order-id":_vm.orderInfo.id,"no-error-tip":true,"service-model":"integral"},on:{"change":function (data) {
          this$1.hasExpressInfo = !!data;
        }}}),_c('div',{staticClass:"shrink",on:{"click":function($event){_vm.openExpressInfo = !_vm.openExpressInfo}}},[_vm._v(" "+_vm._s(_vm.openExpressInfo ? '收起' : '展开')+" ")])],1):_vm._e(),_c('div',{staticClass:"order-detail-bottom-bar"},[(_vm.getBtnShow('取消订单', _vm.orderInfo.status))?_c('van-button',{attrs:{"size":"small","round":"","plain":"","color":"#666"},on:{"click":_vm.handleOnCancelOrder}},[_vm._v("取消订单 ")]):_vm._e(),(_vm.getBtnShow('退换/售后', _vm.orderInfo.status))?_c('van-button',{attrs:{"size":"small","round":"","plain":"","color":"#666"},on:{"click":_vm.handleApplyForReturn}},[_vm._v("退换/售后 ")]):_vm._e(),(_vm.getBtnShow('付款', _vm.orderInfo.status))?_c('van-button',{attrs:{"size":"small","round":"","type":"primary"},on:{"click":_vm.handlePayment}},[_vm._v("付款 ")]):_vm._e(),(_vm.getBtnShow('再次购买', _vm.orderInfo.status))?_c('van-button',{attrs:{"size":"small","round":"","plain":"","type":"primary"},on:{"click":_vm.handleBuyAgain}},[_vm._v("再次购买 ")]):_vm._e(),(_vm.getBtnShow('确认收货', _vm.orderInfo.status))?_c('van-button',{attrs:{"size":"small","round":"","type":"primary"},on:{"click":_vm.handleConfirmOrder}},[_vm._v("确认收货 ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }